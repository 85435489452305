<template>
  <v-dialog
    v-model="model"
    transition="scale-transition"
    :max-width="windows[tab].width"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card
      :style="`border-top-color: ${color};`"
      class="section-card elevation-3 rounded-lg d-flex flex-column"
      :min-width="windows[tab].width"
      :max-width="windows[tab].width"
      :min-height="windows[tab].minHeight"
      :max-height="windows[tab].maxHeight"
    >
      <o-loader
        v-if="!section"
        class="fill-height flex-grow-1"
      />

      <v-card-title v-if="section">
        <span
          :style="`color: ${textColor};`"
        >
          {{ sectionName }}
        </span>

        <v-spacer />

        <span>
          <v-btn
            icon
            @click="$emit('close')"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </span>
      </v-card-title>

      <v-card-text v-if="section">
        <v-tabs
          v-model="tab"
          centered
          text
          color="secondary"
          background-color="transparent"
          hide-slider
        >
          <v-tab
            v-for="(window, i) in windows"
            :key="`window-${i}`"
          >
            {{ $t(window.text) }}
          </v-tab>
        </v-tabs>
      </v-card-text>

      <v-card-text
        v-if="section"
        class="flex-grow-1 fill-height d-flex flex-column"
      >
        <component
          :is="selectedWindow.component"
          :value="sectionId"
          :date="date"
          v-bind="selectedWindow.attrs"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    components: {
      Information: () => import('@/views/admin/components/dashboard/sectionModal/window/Information'),
      History: () => import('@/views/admin/components/dashboard/sectionModal/window/History'),
      Stats: () => import('@/views/admin/components/dashboard/sectionModal/window/Stats'),
    },

    props: {
      sectionId: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: true,
      },
      textColor: {
        type: String,
        required: true,
      },
      date: {
        type: Date,
        default: null,
      },
    },

    apollo: {
      section: {
        query: require('@gql/views/admin/components/dashboard/section-modal/getSection.gql'),
        client: 'floating-traffic-data',
        fetchPolicy: 'no-cache',
        variables: function () {
          return {
            id: this.sectionId,
          };
        },
        update: function (data) {
          return data.getSection;
        },
      },
    },

    data: function () {
      return {
        model: false,
        tab: 0,
        windows: [
          {
            text: 'messages.views.admin.components.dashboard.sectionModal.sectionModal.windows.info',
            width: 550,
            minHeight: 575,
            component: 'Information',
            attrs: {
              date: this.date,
            },
          },
          {
            text: 'messages.views.admin.components.dashboard.sectionModal.sectionModal.windows.history',
            width: 1280,
            component: 'History',
          },
          {
            text: 'messages.views.admin.components.dashboard.sectionModal.sectionModal.windows.stats',
            width: 1280,
            component: 'Stats',
          },
        ],
      };
    },

    computed: {
      selectedWindow () {
        return this.windows[this.tab];
      },
      sectionName () {
        if (this.section.description) {
          return this.section.description;
        } else if (this.section.tmcCode) {
          return this.$t('messages.views.admin.components.dashboard.sectionModal.sectionModal.title.fromTmc', {
            tmc: this.section.tmcCode,
          });
        } else {
          return this.$t('messages.views.admin.components.dashboard.sectionModal.sectionModal.title.undefined');
        }
      },
    },

    watch: {
      model: function (newValue, oldValue) {
        if (!newValue) {
          this.$emit('close');
        }
      },
    },

    mounted () {
      this.model = this.sectionId !== null;
    },
  };
</script>

<style scoped lang="sass">
  .section-card
    border-top-width: 5px
    border-top-style: solid
</style>
